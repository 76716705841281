<template>
  <draggable
    class="grid-drag-area"
    :list="dragItems"
    group="draggable-items-group"
    :itemKey="id"
    :animation="200"
    ghost-class="moving-card"
    filter=".action-button"
  >
    <!-- Define the item slot for each draggable item -->
    <template v-slot:item="{ element }">
      <div
        class="drag-item"
        :class="{ 'invalid-email': isEmailInvalid && element.type === 'email' }"
        :key="element.id"
      >
        <v-icon :name="element.logo" class="item-icons"></v-icon>
        {{ element.name }}
      </div>
    </template>
  </draggable>
</template>

<script>
import draggable from "vuedraggable";

import { OhVueIcon, addIcons } from "oh-vue-icons";
import {
  IoCloseOutline,
  IoCloseCircleOutline,
  MdEmailOutlined,
  HiUser,
  HiUsers,
  RiMessage2Line,
  IoClose,
} from "oh-vue-icons/icons";

addIcons(
  IoCloseOutline,
  IoCloseCircleOutline,
  MdEmailOutlined,
  HiUser,
  HiUsers,
  RiMessage2Line,
  IoClose
);

export default {
  components: {
    draggable,
    "v-icon": OhVueIcon,
  },
  props: {
    dragItems: Array,
    userEmailValidity: String,
    isEmailInvalid: Boolean,
  },
};
</script>

<style scoped>
.grid-drag-area {
  padding: 20px 15px;
  border-radius: 8px;
  background-color: #f9f9f9;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  width: 134px;
}

.drag-item {
  margin: 5px 0px 10px 0px;
  padding: 4px 6px;
  font-size: 14px;
  color: #474646;
  cursor: pointer;
  border: 1px solid #cccccc;
  border-radius: 8px;
  box-sizing: border-box;
}

.item-icons {
  font-size: 4px;
  color: #474646;
}

.moving-card {
  opacity: 0.5;
  background-color: #f7fafc;
  border: 1px solid #3182ce;
}

.invalid-email {
  animation: blink 1s linear infinite;
  border-color: #b0b0b0;
  background-color: #f0f0f0;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

/* Blinking animation */
@keyframes blink {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0.7;
  }
}
</style>